<template>
    <transition appear enter-active-class="animated fadeIn">
        <div>
            <div id="header-title" class="level-item">
                <h1 class="title is-1 page-title">Insurance Product Information Document (IPID)</h1>
            </div>
            <div class="box b-shdw-3">

                <div class="box ipid-box background-c2g-blue has-text-white">
                    <div class="columns">
                        <div class="column is-1">
                            <b-icon pack="fa" icon="file-pdf" size="is-large" type="is-primary"/>
                        </div>
                        <div class="column">
                            <p>Single Trip</p>
                            <hr class="sep">
                            <a :href="$c2g.getIPID('platinum', false, 'ST')" target="_blank">Platinum IPID</a> |
                            <a :href="$c2g.getIPID('gold', false, 'ST')" target="_blank">Gold IPID</a> |
                            <a :href="$c2g.getIPID('silver', false, 'ST')" target="_blank">Silver IPID</a>
                        </div>
                    </div>
                </div>

                <div class="box ipid-box background-c2g-blue has-text-white">
                    <div class="columns">
                        <div class="column is-1">
                            <b-icon pack="fa" icon="file-pdf" size="is-large" type="is-primary"/>
                        </div>
                        <div class="column">
                            <p>Annual Multi-Trip</p>
                            <hr class="sep">
                            <a :href="$c2g.getIPID('platinum', false, 'AT')" target="_blank">Platinum IPID</a> |
                            <a :href="$c2g.getIPID('gold', false, 'AT')" target="_blank">Gold IPID</a> |
                            <a :href="$c2g.getIPID('silver', false, 'AT')" target="_blank">Silver IPID</a>
                        </div>
                    </div>
                </div>

                <div class="box ipid-box background-c2g-blue has-text-white">
                    <div class="columns">
                        <div class="column is-1">
                            <b-icon pack="fa" icon="file-pdf" size="is-large" type="is-primary"/>
                        </div>
                        <div class="column">
                            <p>Longstay</p>
                            <hr class="sep">
                            <a :href="$c2g.getIPID('platinum', false, 'LS')" target="_blank">Longstay IPID</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "PolicyIPID"
    }
</script>

<style lang="scss" scoped>
    h5 {
        color: $c2g_orange;
        margin-bottom: 8px !important;
        text-align: center;
        border-bottom: 3px $c2g_orange solid;
        padding-bottom: 8px;
    }
    .ipid-box {
        border-bottom: 3px $c2g_orange solid;
    }
    #header-title {
        padding: 40px 0 40px 0;
    }
    .sep {
        margin-top: 5px;
        margin-bottom: 5px;
    }
</style>